<template>
  <section id="master-group-belanja">
    <b-row>
      <b-col
        cols="12"
      >
        <b-card>
          <div class="d-flex justify-content-end mb-2">
            <b-button
              class="btn btn-success"
              @click="addTemplate()"
            >
              Tambah Data Master
            </b-button>
          </div>

          <template v-if="alert">
            <b-alert
              :variant="alertDetail.variant"
              class="mt-2"
              show
            >
              <h4 class="alert-heading">
                {{ alertDetail.title }}
              </h4>
              <div class="alert-body">
                {{ alertDetail.text }}
              </div>
            </b-alert>
          </template>

          <!-- table -->
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <!-- Column: Action -->
              <span v-if="props.column.field == 'action'">
                <b-button
                  variant="info"
                  size="sm"
                  class="m-25"
                  @click="editTemplate(props.row)"
                >
                  <feather-icon
                    icon="Edit3Icon"
                    size="12"
                  /></b-button>
                <b-button
                  variant="outline-danger"
                  size="sm"
                  class="m-25"
                  @click="removeTemplate(props.row.id, props.row.originalIndex)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    size="12"
                  />
                </b-button>
              </span>
              <span v-else-if="props.column.field == 'account'">
                {{ (props.row.account) ? props.row.account.name : '-' }}
              </span>
              <span v-else-if="props.column.field == 'instansi'">
                {{ (props.row.instansi) ? props.row.instansi.name : '-' }}
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['10','25','50']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>

      </b-col>
      <!-- post -->
    </b-row>

    <b-modal
      id="modal-komponen"
      ref="modal-komponen"
      ok-variant="success"
      cancel-title="Batal"
      ok-title="Simpan"
      centered
      no-close-on-backdrop
      title="Tambah Jenis Belanja"
      @ok.prevent="validationForm()"
    >
      <b-card-text>
        <validation-observer ref="simpleRules">
          <b-form>
            <b-form-group
              label="Nama"
              label-for="vi-name"
            >
              <validation-provider
                #default="{ errors }"
                name="Nama"
                rules="required"
              >
                <b-form-input
                  id="vi-name"
                  v-model="name"
                  required
                  name="name"
                  placeholder="Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Kode"
              label-for="vi-code"
            >
              <validation-provider
                #default="{ errors }"
                name="Kode"
                rules="required"
              >
                <b-form-input
                  id="vi-code"
                  v-model="code"
                  required
                  name="code"
                  placeholder="Kode"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Deskripsi"
              label-for="vi-description"
            >
              <validation-provider
                #default="{ errors }"
                name="Deskripsi"
                rules="required"
              >
                <b-form-textarea
                  id="vi-description"
                  v-model="description"
                  rows="4"
                  required
                  name="description"
                  placeholder="Deskripsi"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-warning"
      ref="modal-warning"
      cancel-variant="outline-secondary"
      ok-variant="warning"
      cancel-title="Batal"
      ok-title="Ya, Hapus!"
      modal-class="modal-warning"
      centered
      title="Attention!"
      @cancel="clearDeleteItem()"
      @ok="deleteItem()"
    >
      <b-card-text>
        Hapus Jenis Belanja?
      </b-card-text>
    </b-modal>
  </section>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BForm, BFormTextarea, BRow, BButton, BCol, BCard, BModal, BPagination, BFormGroup, BFormSelect, BCardText, BAlert, BFormInput,
} from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'
import { required } from '@validations'

export default {
  components: {
    BForm,
    BAlert,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BCardText,
    BButton,
    BCard,
    BModal,
    BRow,
    BCol,
    VueGoodTable,
    BPagination,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
  },
  beforeRouteEnter(to, from, next) {
    const role = Number(localStorage.getItem('roleId'))
    if (role === '10') {
      next('/')
    } else {
      next()
    }
  },
  data() {
    return {
      required,
      userData: getUserData(),
      alert: false,
      alertDetail: {
        variant: 'info',
        title: 'Info',
        text: '',
      },
      name: '',
      code: '',
      level: 1,
      description: '',
      id_data: '',
      pageLength: 10,
      columns: [
        {
          label: 'Nama',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Nama',
          },
        },
        {
          label: 'Deskripsi',
          field: 'description',
          filterOptions: {
            enabled: true,
            placeholder: 'Deskripsi',
          },
        },
        {
          label: 'Status',
          field: 'status',
          filterOptions: {
            enabled: true,
            placeholder: 'Status',
          },
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
      toDelData: '',
      toDelItem: '',
    }
  },
  created() {
    this.clearance_id = this.$route.query.id
    this.getData()
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.simpanTemplate()
        }
      })
    },
    getData() {
      this.$http.get('/users/account', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
        params: {
          'orderBy[]': 'name:asc',
          limit: 1000,
        },
      })
        .then(res => {
          this.rows = res.data.data
        })
    },
    clearTemplate() {
      this.id_data = ''
      this.name = ''
      this.code = ''
      this.description = ''
      this.alert = false
    },
    addTemplate() {
      this.clearTemplate()
      this.$refs['modal-komponen'].show()
    },
    editTemplate(data) {
      this.id_data = data.id
      this.name = data.name
      this.code = data.code
      this.description = data.description

      this.$refs['modal-komponen'].show()
    },
    simpanTemplate() {
      const formData = {
        name: this.name,
        code: this.code,
        level: this.level,
        description: this.description,
      }

      if (this.id_data !== '') {
        this.$http.put(`/users/account/${this.id_data}`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          },
        })
          .then(res => {
            if (res.data.success) {
              this.alert = true
              this.alertDetail = {
                variant: 'success',
                title: 'Data Saved',
                text: res.data.message,
              }
              this.getData()
              this.$refs['modal-komponen'].hide()

              setTimeout(() => {
                this.clearTemplate()
              }, 2000)
            } else {
              this.alert = true
              this.alertDetail = {
                variant: 'danger',
                title: 'Error Found',
                text: res.data.message,
              }
            }
          })
          .catch(error => {
            this.alert = true
            this.alertDetail = {
              variant: 'danger',
              title: 'Error Found',
              text: (error.response.data.message !== '') ? error.response.data.message : error,
            }
          })
      } else {
        this.$http.post('/users/account', formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          },
        })
          .then(res => {
            if (res.data.success) {
              this.alert = true
              this.alertDetail = {
                variant: 'success',
                title: 'Data Saved',
                text: res.data.message,
              }
              this.getData()
              this.$refs['modal-komponen'].hide()

              setTimeout(() => {
                this.clearTemplate()
              }, 2000)
            } else {
              this.alert = true
              this.alertDetail = {
                variant: 'danger',
                title: 'Error Found',
                text: res.data.message,
              }
            }
          })
          .catch(error => {
            this.alert = true
            this.alertDetail = {
              variant: 'danger',
              title: 'Error Found',
              text: (error.response.data.message !== '') ? error.response.data.message : error,
            }
          })
      }
    },
    removeTemplate(value, index) {
      this.toDelData = value
      this.toDelItem = index
      this.$refs['modal-warning'].show()
    },
    clearDeleteItem() {
      this.toDelData = ''
      this.toDelItem = ''
    },
    deleteItem() {
      this.$http.delete(`/users/account/${this.toDelData}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(res => {
          this.rows.splice(this.toDelItem, 1)
          this.clearDeleteItem(res)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
